// Styling for Komoot page

div.komoot {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 100px;

  color: white;
  background-color: $theme-primary;

  .image {
    margin-top: 3%;
  }

  .text {
    text-transform: uppercase;
  }

  #textCong {
    margin-top: 2%
  }

  @media(max-width: 768px) {
    div {
      padding-top: 30%;
    }
  }

}