// Styling for Welcome page

div.welcome {
  position: relative;
  height: 100vh;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 100px;

  color: white;
  background-color: $theme-primary;

  .image {
    margin-top: 1%;
  }

  .text {
    text-transform: uppercase;
    margin-top: 6%;
    margin-left: 4%;
    margin-right: 4%
  }

  #textCong {
    margin-top: 2%
  }

  @media(max-width: 768px) {
    div {
      padding-top: 30%;
    }

    .text {
      text-transform: uppercase;
      margin-top: 10%;
      margin-left: 4%;
      margin-right: 4%
    }

  }

}