div.contact {
  position: relative;

  .container {
    padding-top: 2%;
  }

  .image {
    width: 25%
  }

  @media(max-width: 768px) {

    .container {
      padding-top: 10%;
    }

    .image {
      width: 50%
    }

  }
}