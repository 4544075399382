div.cookie {
  width: 100%;
  background-color: black;
  padding: 1%;

  div.content {
    display: flex;
    align-items: center;
    justify-content: center;

    div.text {
      font-size: medium;
      color: white;
    }

    a.text-more {
      font-size: medium;
      color: white;
    }

    button.button-text {
      font-size: x-small;
      margin-right: 1%;
      margin-left: 30%;
      width: 100%

    }

  }

  @media(max-width: 768px) {
    div.content {
      display: block;

      div.text {
        font-size: medium;
        color: white;
        text-align: center;
      }

      div.div-button {
        align-items: center;
        justify-content: center;
        margin-top: 2%;
        margin-bottom: 2%;
      }

      a.text-more {
        font-size: medium;
        color: white;

      }

      button.button-text {
        font-size: x-small;
        margin: 0 auto; // auto left/right margins
        display: block;
        width: 30%
      }
    }
  }

}