// Bootstrap overrides for this template
.bg-primary {
    background: $theme-primary;
    background: -webkit-linear-gradient($theme-primary, darken($theme-primary, 5%));
    background: linear-gradient($theme-primary, darken($theme-primary, 5%));
}

.bg-secondary {
    background: $theme-secondary;
    background: -webkit-linear-gradient($theme-secondary, darken($theme-secondary, 5%));
    background: linear-gradient($theme-secondary, darken($theme-secondary, 5%));
}

.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.btn-outline {
    color: white;
    border: 1px solid;
    border-color: white;
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: white;
        border-color: $theme-primary;
        background-color: $theme-primary;
    }
}

.btn {
    border-radius: 300px;
    @include alt-font;
}

.btn-xl {
    font-size: 11px;
    padding: 15px 45px;
}

.komoot{
    background-color: $theme-primary;
}
