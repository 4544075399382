// Styling for the masthead
header.masthead {
    position: relative;

    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;

    color: white;
    background-color: $theme-primary;
    .header-content {
        max-width: 500px;
        margin-bottom: 100px;

        text-align: center;
        h1 {
            font-size: 30px;
        }
    }
    .device-container {
        max-width: 325px;
        margin-right: auto;
        margin-left: auto;
        .screen img {
            border-radius: 3px;
        }
    }
    @media (min-width: 992px) {
        height: 100vh;
        min-height: 775px;
        padding-top: 0;
        padding-bottom: 0;
        .header-content {
            margin-bottom: 0;

            text-align: left;
            h1 {
                font-size: 50px;
            }
        }
        .device-container {
            max-width: 325px;
        }
    }
}