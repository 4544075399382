.popup {
  background-color: white;
  border: medium lightgray solid;
  border-radius: 10px;
  @media(max-width: 768px) {
    border: thin lightgray solid;
    border-radius: 10px;
  }
}
.popup_inner {
  left: 30%;
  right: 30%;
  top: 30%;
  bottom: 30%;

  @media(max-width: 768px) {
    left: 10%;
    right: 10%;
    top: 10%;
    bottom: 10%;
  }


  .popup_center{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .popup_button_close {
    position: absolute;
    right: 5%;
    top: 3.5%;

    @media(max-width: 768px) {
      top: 5%;
      right: 8%
    }
  }

  .popup_headline {
    margin-top: 4%;
    text-transform: uppercase;
    color: $theme-primary;
    font-weight: bold;
  }

  .popup_text {
    color: $theme-primary;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 4%;
    text-align: center;

    @media(max-width: 768px) {
      margin-top: 10%;
    }
  }

  .popup_image {
    width: 40%;
    height: auto;
    margin-top: 2%;

    @media(max-width: 768px) {
      margin-top: 5%;
      width: 100%;

    }
  }
}
